import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import "./publicPolicy.scss"
import { withTrans } from "../../i18n/withTrans"
import { useTranslation } from "react-i18next"
import { StaticImage } from "gatsby-plugin-image"

const PublicPolicy = () => {
  const { t } = useTranslation()

  return (
    <Layout className="public-policy">
      <SEO title={t("footer.text-publicpolicy")} />
      <StaticImage src="../../assets/images/banner-public-policy.png" />
      <div className="public-privacy-content">
        <div className="section">
          <h5 dangerouslySetInnerHTML={{ __html: content.title }} />
          {typeof content.description === "object" ? (
            content.description.map((el, i) => (
              <>
                {typeof el === "object" ? (
                  el.isTitle ? (
                    <h5
                      dangerouslySetInnerHTML={{ __html: el.title }}
                      className="underlined-title"
                    />
                  ) : (
                    <tr>
                      <td>
                        <p> {el.sequence}.</p>
                      </td>
                      <td></td>
                      <td>
                        {el.description.length === 0 ? (
                          <p dangerouslySetInnerHTML={{ __html: el.title }} />
                        ) : (
                          <>
                            <p dangerouslySetInnerHTML={{ __html: el.title }} />
                            {el.description.map((subEl, idx) => (
                              <tr>
                                <td>
                                  <p> {idx + 1}.</p>
                                </td>
                                <td></td>
                                <td>
                                  <p
                                    dangerouslySetInnerHTML={{
                                      __html: subEl,
                                    }}
                                  />
                                </td>
                              </tr>
                            ))}
                          </>
                        )}
                      </td>
                    </tr>
                  )
                ) : (
                  <tr>
                    <td>
                      <p> {i + 1}.</p>
                    </td>
                    <td></td>
                    <td>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: el,
                        }}
                      />
                    </td>
                  </tr>
                )}
              </>
            ))
          ) : (
            <p dangerouslySetInnerHTML={{ __html: content.description }} />
          )}
        </div>
      </div>
    </Layout>
  )
}

const content = {
  title:
    "Kebijakan umum ini mencakup implementasi sistem manajemen keamanan informasi dan sistem manajemen informasi privasi di PT Pintar Inovasi Digital adalah sebagai berikut:",
  description: [
    "Informasi merupakan salah satu aset utama dalam setiap kegiatan yang diselenggarakan oleh PT Pintar Inovasi Digital. Oleh karena itu, kerahasiaan (confidentiality), kebenaran (integrity), dan ketersediaan (availability) informasi perlu dikelola sehingga keamanannya dapat terjaga.",
    "Penerapan sistem manajemen keamanan informasi dan sistem manajemen informasi privasi di PT Pintar Inovasi Digital mengacu pada standar ISO/IEC 27001:2013, ISO/IEC 27701:2019 dan peraturan perundang-undangan yang berlaku.",
    "PT Pintar Inovasi Digital senantiasa menunjukkan kepemimpinan dan komitmen untuk menerapkan sistem manajemen keamanan informasi dan sistem manajemen informasi privasi di organisasi.",
    "Kebijakan keamanan informasi dan privasi harus dikomunikasikan ke seluruh karyawan dan pihak eksternal yang terkait melalui media komunikasi yang ada agar dapat dengan mudah dipahami dan dipatuhi.",
    "PT Pintar Inovasi Digital akan selalu berusaha meningkatkan kepedulian (awareness), pengetahuan, dan keterampilan tentang keamanan informasi dan privasi bagi karyawan maupun pihak eksternal yang terkait.",
    "Perusahaan melaksanakan kajian dan mengelola risiko-risiko terkait keamanan informasi dan keamanan privasi berdasarkan kerentanan (vulnerability) dan ancaman (threat) yang ada pada setiap aset maupun proses.",
    "Jika ada kerentanan dan ancaman yang berpotensi mengganggu keamanan informasi dan keamanan privasi, maka semua pihak yang berkepentingan wajib melaporkannya kepada Ketua Tim SMKI dan SMIP atau anggota Tim SMKI dan SMIP.",
    "PT Pintar Inovasi Digital mengidentifikasi dan mengklasifikasikan informasi yang sensitif atau rahasia untuk menentukan tingkat pelindungan yang diperlukan.",
    "PT Pintar Inovasi Digital akan mengendalikan personil yang dapat mengakses informasi sensitif adalah bagian penting dari manajemen privasi informasi.",
    "Jenis informasi pribadi yang dikumpulkan, digunakan, dan diproses baik secara langsung maupun tidak langsung oleh PT Pintar Inovasi Digital bertujuan untuk operasional bisnis yang terkait dengan peer to peer lending.",
    "PT Pintar Inovasi Digital hanya mengumpulkan informasi pribadi yang diperlukan untuk tujuan yang jelas dan ditentukan.",
    "PT Pintar Inovasi Digital menjunjung hak individu terkait dengan informasi pribadi, termasuk hak untuk mengakses, memperbaiki, atau menghapus informasi pribadi dari sistem sesuai prosedur Pengendalian dan Pemrosesan PII.",
    "PT Pintar Inovasi Digital akan melindungi keamanan informasi pribadi dari akses yang tidak sah, penggunaan yang tidak sah, atau kebocoran data. Ini meliputi penggunaan teknologi enkripsi, pengaturan akses yang tepat, tindakan keamanan fisik, serta prosedur pemantauan dan pelaporan insiden keamanan.",
    "Seluruh pimpinan pada semua tingkatan bertanggung jawab memantau dan mengevaluasi efektivitas penerapan kebijakan ini di seluruh unit kerja/bagian di bawah pengawasannya sebagai komitmen dalam peningkatan berkelanjutan.",
    "Seluruh pegawai bertanggung jawab untuk menjaga dan melindungi keamanan aset informasi serta mematuhi kebijakan dan prosedur keamanan informasi dan keamanan privasi yang telah ditetapkan.",
    "Apabila ditemukan pelanggaran terhadap kebijakan ini dan kebijakan lain yang terkait maka PT Pintar Inovasi Digital berhak memberikan sanksi administratif seperti pencabutan hak akses sistem informasi dan/atau tindakan pendisiplinan lain sesuai peraturan yang berlaku.",
    "Kebijakan dan prosedur yang bersifat lebih teknis akan dibuat secara terpisah dan ditetapkan dengan merujuk prinsip-prinsip yang ditetapkan dalam pernyataan kebijakan ini.",
  ],
}

export default withTrans(PublicPolicy)
